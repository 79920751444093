import $ from "jquery";
import { Breakpoints } from "../breakpoints"
import { triggerAnalyticsEvent } from '../shared/analytics'
import { ANALYTICS_EVENT } from "./analyticsEvent";

const rotationDelay = 2000;
const resetDelay = 5000;
const $dinamicContent = $("[data-service-content]");
const $menuItems = $("[data-service-title]");
const $indicator = $("[data-service-bar]");
const $services = $("[data-service-container]");
const $card = $("[data-service-card-mobile]");

function showContent(service) {
  $dinamicContent.each(function () {
    $(this).addClass("hidden").removeClass("fade-in");
  });

  $("[data-service-content='" + service + "']")
    .removeClass("hidden")
    .addClass("fade-in");
}

function titleInteraction(rotationDelay, resetDelay) {
  let autoRotateInterval;
  let autoRotateTimeout;

  function activateTitle($this) {
    const service = $this.data("service-title");
    const currentIndex = $(".section-active").index();
    const targetIndex = $this.index();

    $dinamicContent.removeClass("before after");

    if (targetIndex > currentIndex) {
      $("[data-service-content='" + service + "']").addClass("after");
    } else if (targetIndex < currentIndex) {
      $("[data-service-content='" + service + "']").addClass("before");
    }

    showContent(service);

    $menuItems.removeClass("section-active");
    $this.addClass("section-active");

    updateIndicator($this);
  }

  function updateIndicator($item) {
    const dimensions = $item.position();
    const height = $item.outerHeight();

    $indicator.css({
      top: dimensions.top + "px",
      height: height + "px",
    });
  }

  function startAutoRotate() {
    if (autoRotateInterval) return;
    autoRotateInterval = setInterval(() => {
      const $current = $(".section-active[data-service-title]");
      const $next = $current.next("[data-service-title]").length
        ? $current.next("[data-service-title]")
        : $("[data-service-title]").first();

      activateTitle($next);
    }, rotationDelay);
  }

  function stopAutoRotate() {
    clearInterval(autoRotateInterval);
    autoRotateInterval = null;
  }

  function resetAutoRotate() {
    stopAutoRotate();
    clearTimeout(autoRotateTimeout);
    autoRotateTimeout = setTimeout(startAutoRotate, resetDelay);
  }

  $menuItems.on("click", function () {
    const $this = $(this);
    const analytics = $this.data("analytics");

    if ($this.hasClass("section-active")) {
      return;
    }

    const dynamicEvent = `${ANALYTICS_EVENT.WhatWeDo}_${analytics}`;
    triggerAnalyticsEvent(dynamicEvent);
    activateTitle($this);
  });

  $services.on("mouseenter", function () {
    stopAutoRotate();
    clearTimeout(autoRotateTimeout);
  });

  $services.on("mouseleave", function () {
    resetAutoRotate();
  });

  startAutoRotate();
}

function titlesBar() {
  let initialDimensions = [];

  function initialize() {
    initialDimensions = $menuItems
      .map(function () {
        const $this = $(this);
        const originalClass = $this.attr("class");
        $this.removeClass("section-active");
        const rect = $this[0].getBoundingClientRect();
        $this.attr("class", originalClass);
        return {
          height: rect.height,
          top: $this.position().top,
        };
      })
      .get();

    function updateIndicator($item) {
      const index = $menuItems.index($item);
      const dimensions = initialDimensions[index];

      $indicator.css({
        top: dimensions.top + "px",
        height: dimensions.height + "px",
      });
    }

    const $activeItem = $menuItems.filter(".section-active");
    if ($activeItem.length) {
      updateIndicator($activeItem);
    } else {
      const $firstItem = $menuItems.first();
      $firstItem.addClass(".section-active");
      updateIndicator($firstItem);
    }
  }

  initialize();

	$(window).on("resize", function () {
    $menuItems.removeClass(".section-active");

    initialize();
  });
}

function mobileAccordion() {
  
  $card.on("click", function () {
    let isActive = $(this).hasClass("accordion-active");
    const analytics = $(this).data("analytics");
    
    $card.removeClass("accordion-active");
    $(this).toggleClass("accordion-active", !isActive);
    
    if(!isActive){
      const dynamicEvent = `${ANALYTICS_EVENT.WhatWeDo}_${analytics}`;
      triggerAnalyticsEvent(dynamicEvent);
    }
  });
}

$(document).ready(function () {
	if ($(window).width() >= Breakpoints.desktop) {
		titlesBar();
		titleInteraction(rotationDelay, resetDelay);
	} else {
		mobileAccordion();
	}
});
